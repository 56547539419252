import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import brandlogo from "../../images/clever-compliance.png";
import MenuIcon from "@material-ui/icons/Menu";
import {
  registerProductFeedWidget,
  unregisterProductFeedWidget,
} from "../../utils/productFeed";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import { Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";

export default observer(function Header() {
  const { sessionStore, productsStore } = useRootStore();
  const navigate = useNavigate();

  //TODO: Should be done differently
  function toggleSidebar() {
    document.body.classList.toggle("aside-toggled");
  }

  useEffect(() => {
    registerProductFeedWidget();

    return unregisterProductFeedWidget;
  }, []);

  const customDropdownToggle = React.forwardRef(
    (props, ref: React.Ref<HTMLAnchorElement>) => {
      let params = props as any;
      return (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="last-viewed-products-tooltip">
              {params.disabled ? "No recent products" : "Recent products"}
            </Tooltip>
          }
        >
          <a
            href="#"
            ref={ref}
            onClick={(e) => {
              e.preventDefault();
              if (params.disabled) return;
              params.onClick(e);
            }}
          >
            {params.children}
          </a>
        </OverlayTrigger>
      );
    }
  );

  return (
    <header className="topnavbar-wrapper">
      <nav className="navbar topnavbar justify-content-between px-2 px-md-0">
        <div className="navbar-header pl-md-0">
          <Link to="/">
            <div className="brand-logo">
              <img
                src={brandlogo}
                alt="Clever Compliance"
                title="Clever Compliance"
                className="img-fluid"
              />
            </div>
          </Link>
        </div>
        <ul className="navbar-nav flex-row">
          <li className="nav-item">
            <a
              href="#"
              onClick={toggleSidebar}
              className="d-flex h-100 align-items-center nav-link sidebar-toggle px-4 d-md-none"
            >
              <MenuIcon style={{ fill: "#fff" }} />
            </a>
          </li>
        </ul>
        <ul className="navbar-nav flex-row mr-3">
          {sessionStore.authenticated ? (
            <li className="nav-item d-flex align-items-center position-relative mr-5 pr-4">
              <Dropdown>
                <Dropdown.Toggle
                  disabled={productsStore.lastViewedProducts.length === 0}
                  as={customDropdownToggle}
                  id="dropdown-custom-components"
                >
                  <SettingsBackupRestoreIcon
                    className={
                      productsStore.lastViewedProducts.length > 0
                        ? "text-white"
                        : ""
                    }
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Header>Recent products</Dropdown.Header>
                  {productsStore.lastViewedProducts.map((a) => (
                    <Dropdown.Item
                      onClick={() => navigate(`/products/${a.id}`)}
                      key={a.id}
                    >
                      {a.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null}
          <li className="nav-item d-flex align-items-center position-relative mr-5 pr-4">
            <a id="productfeed-trigger" href="#" className="text-white">
              What's new
            </a>
          </li>
          <li className="nav-item d-flex align-items-center">
            {!sessionStore.authenticated ? (
              <button
                type="button"
                className="btn d-inline-flex btn-oval btn-outline-light"
                onClick={() => {
                  sessionStore.signIn();
                }}
              >
                Sign in
              </button>
            ) : (
              <button
                type="button"
                className="btn d-inline-flex btn-oval btn-outline-light"
                onClick={() => {
                  sessionStore.signOut();
                }}
              >
                Sign out
              </button>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
});

import React, { useState, useContext, useEffect, useCallback, Suspense } from "react"
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import AppsIcon from '@material-ui/icons/Apps';
import ExploreIcon from '@material-ui/icons/Explore';
import TocIcon from '@material-ui/icons/Toc';
import { Tooltip, OverlayTrigger, Modal, Badge } from "react-bootstrap";
import SettingsIcon from '@material-ui/icons/Settings';
import { useLocation, NavLink } from 'react-router-dom'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TaskList from '@material-ui/icons/Done';

import "../../styles/Sidebar.css";

import { PermissionsContext } from "../Auth/AuthorizationProvider";
import ContactMe from "../Home/ContactMe";
import { Unwrap } from "../../utils/api/apiClient";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";
import lazyWithRetry from "../../LazyWithRetry";

const SpaceSelector = lazyWithRetry(() => import("../Admin/SpaceSelector"));

export default observer(function Sidebar() {
   let { pathname } = useLocation();
   let adminActive = pathname.startsWith("/admin");
   const { apiClient, sessionStore } = useRootStore();
   const [viewContactModal, setViewContactModal] = useState(false);
   const [notificationsCount, setNotificationsCount] = useState<{ hidden: number, total: number, visible: number }>({ hidden: 0, total: 0, visible: 0 });
   const [tasksCount, setTasksCount] = useState<{total: number}>({ total: 0});

   const permissionsContext = useContext(PermissionsContext);

   const getNotificationsCount = useCallback(function () {
      apiClient.get("notifications/count").then(a => {
         setNotificationsCount(Unwrap.result(a));
      })
   }, [apiClient]);

   const getTasksCount = useCallback(function () {
      apiClient.get("Tasks/count").then(a => {
         setTasksCount(Unwrap.result(a));
      })
   }, [apiClient]);

   useEffect(() => {
      window.addEventListener("notifications.changed", getNotificationsCount);

      return () => {
         window.removeEventListener("notifications.changed", getNotificationsCount);
      }
   }, [getNotificationsCount]);

   useEffect(() => {
      window.addEventListener("tasks.changed", getTasksCount);

      return () => {
         window.removeEventListener("tasks.changed", getTasksCount);
      }
   }, [getTasksCount]);

   useEffect(() => {
      if (!sessionStore.currentSpace) {
         setNotificationsCount({ hidden: 0, total: 0, visible: 0 });
         return;
      }
      getNotificationsCount();
   }, [getNotificationsCount, sessionStore.currentSpace]);

   useEffect(() => {
      if (!sessionStore.currentSpace) {
         setTasksCount({ total: 0 });
         return;
      }
      getTasksCount();
   }, [getTasksCount, sessionStore.currentSpace]);

   return (
      <aside className="aside-container" style={{ transition: "opacity 0.5s ease 0s", opacity: "1" }}>
         <div className="aside-inner">
            {sessionStore.hasSpaces ? <Suspense fallback="Loading..."><SpaceSelector /></Suspense> : null}
            <nav data-sidebar-anyclick-close="" className="sidebar">
               <ul className="sidebar-nav">
                  {
                     sessionStore.user ?
                        <li className="bg-green">
                           <div className="d-flex text-white p-2 ml-3" style={{ "cursor": "pointer" }}>
                              <OverlayTrigger placement="top" overlay={<Tooltip id="user-tooltip">{sessionStore.user.profile.email}</Tooltip>}>
                                 <span>
                                    <AccountCircleIcon color="inherit" fontSize="small" className=" mr-2" />
                                    {sessionStore.user.profile.given_name + " " + sessionStore.user.profile.family_name}
                                 </span>
                              </OverlayTrigger>
                           </div>
                        </li> : null
                  }


                  <li>
                     <NavLink to="/" title="Dashboard" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
                        <MultilineChartIcon color="inherit" fontSize="small" className=" mr-2" />
                        <span>Dashboard</span>
                     </NavLink>
                  </li>
                  {!!sessionStore.license ? <>
                     {!permissionsContext?.state.userPerm.products ? <li className="">
                        <NavLink to={"/products"} title="Products">
                           <TocIcon fontSize="small" className="mr-2" />
                           <span className="ml-2 w-100">Products</span>
                        </NavLink>
                     </li> : null}
                     <li className="">
                        <NavLink to="/tasks" title="Tasks" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
                           <TaskList fontSize="small" />
                           <span className="ml-2 w-100">
                              Tasks
                              {tasksCount.total > 0 ? <Badge className="d-inline-block float-right" color="primary">{tasksCount.total}</Badge> : null}
                           </span>
                        </NavLink>
                     </li>
                     <li className="">
                        <NavLink to={"/notifications"} title="Notifications" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
                           {notificationsCount.visible > 0 ? <NotificationImportantIcon fontSize="small" /> : <NotificationsNoneIcon fontSize="small" />}
                           <span className="ml-2 w-100">
                              Notifications
                              {notificationsCount.visible > 0 ? <Badge className="d-inline-block float-right badge bg-primary">{notificationsCount.visible}</Badge> : null}
                           </span>
                        </NavLink>
                     </li>
                     {sessionStore.license?.licensePermissions?.tools === true ? <li className="">
                        <NavLink to={"/tools"} title="Tools" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center">
                           <AppsIcon fontSize="small" className="mr-2" />
                           <span>Tools</span>
                        </NavLink>
                     </li> : null}
                     <li className="">
                        <NavLink to={"/news"} title="News">
                           <ReceiptIcon fontSize="small" className="mr-2" />
                           <span>News</span>
                        </NavLink>
                     </li>
                     <li className="">
                        <NavLink to={"/admin/admin/users/users"} title="Admin" style={{ "whiteSpace": "normal" }} className={adminActive ? "d-flex align-items-center active" : "d-flex align-items-center"}>
                           <SettingsIcon fontSize="small" className="mr-2" />
                           <span>Admin</span>
                        </NavLink>
                     </li>
                  </> : null}
                  <li className="">
                     <NavLink to={"/store"} title="Store" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center open-store">
                        <ShoppingBasketIcon fontSize="small" className="mr-2" />
                        <span>Store</span>
                     </NavLink>
                  </li>
                  {!sessionStore.license ? <><li className="">
                     <NavLink to={"/needs-guide"} title="Needs guide" style={{ "whiteSpace": "normal" }} className="d-flex align-items-center open-needs-guide">
                        <ExploreIcon fontSize="small" className="mr-2" />
                        <span>Needs guide</span>
                     </NavLink>
                  </li>
                     <li className="mt-3">
                        <a href="#" onClick={(e) => { e.preventDefault(); setViewContactModal(true); }} className="font-weight-bold" style={{ "whiteSpace": "normal" }}>
                           <span aria-label="Alert Octagram icon" role="img" className="material-design-icon alert-octagram-icon icon text-purple">
                              <svg fill="currentColor" width="24" height="24" viewBox="0 0 24 24" className="material-design-icon__svg">
                                 <path d="M2.2,16.06L3.88,12L2.2,7.94L6.26,6.26L7.94,2.2L12,3.88L16.06,2.2L17.74,6.26L21.8,7.94L20.12,12L21.8,16.06L17.74,17.74L16.06,21.8L12,20.12L7.94,21.8L6.26,17.74L2.2,16.06M13,17V15H11V17H13M13,13V7H11V13H13Z">
                                    <title>Alert Octagram icon</title>
                                 </path>
                              </svg>
                           </span>
                           <span className="text-purple">Get more - upgrade</span>
                        </a>
                     </li></>
                     : null}

               </ul>
            </nav>
         </div>
         <Modal size="xl" show={viewContactModal} onHide={() => setViewContactModal(false)}>
            <Modal.Body>
               <ContactMe submitted={() => setViewContactModal(false)} />
            </Modal.Body>
         </Modal>
      </aside>);
});